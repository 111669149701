import React, {useState, useEffect} from 'react';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

function CountdownTimer({targetDate}) {
    const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const [isExpired, setIsExpired] = useState(false);


    useEffect(() => {
        // Set the time zone to CET for target date
        const targetDateTime = new Date(targetDate).toLocaleString('en-US', { timeZone: 'CET' });

        const updateCountdown = () => {
            // Set the time zone to CET for current time
            const currentTime = new Date().toLocaleString('en-US', { timeZone: 'CET' });

            const targetTime = new Date(targetDateTime).getTime();
            const currentTimeInMillis = new Date(currentTime).getTime();
            const timeDifference = targetTime - currentTimeInMillis;

            if (timeDifference > 0) {
                const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                setTimeLeft({ days, hours, minutes, seconds });
                setIsExpired(false);
            } else {
                setIsExpired(true);
            }
        };

        const countdownInterval = setInterval(updateCountdown, 1000);

        return () => {
            clearInterval(countdownInterval);
        };
    }, [targetDate]);


    return (
            <div className="countdown">
                <AccessAlarmIcon />
                <span>{timeLeft.days}d&nbsp;</span>
                <span>{timeLeft.hours}h&nbsp;</span>
                <span>{timeLeft.minutes}m&nbsp;</span>
                <span>{timeLeft.seconds}s&nbsp;</span>
                <span className={`countdown-status ${isExpired ? 'expired' : 'active'}`}>
                    <FiberManualRecordIcon />
                    <span>LIVE</span>
                </span>
            </div>
    );
}

export default CountdownTimer;