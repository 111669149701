import React, {useContext, useState} from 'react';
import {MetaVrsContext} from "../store/MetaVrsContext";

import Loader from "../utils/Loader";
import ErrorLogin from "./errors/ErrorLogin";
import LoginButton from "../utils/LoginButton";
import GoBackButton from "../components/GoBackButton";
import Mynfts from "../components/Profile/Mynfts";
import {ProfileInfos} from "../components/Profile/ProfileInfos";

import "../styles/profile.css";

import Logo from "../assets/logo_michelin.svg"


const Profile = () => {
    const metaVrs = useContext(MetaVrsContext);

    const [activeLink, setActiveLink] = useState('A');


    if (!metaVrs.isSdkLoaded) {return <Loader />}
    if (!metaVrs.isUserLogged) {return <ErrorLogin /> }


    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    return (
        <div className="michelin-profile">
            <header>
                <div className="row"><img src={Logo} height="60px" alt=""/><LoginButton /></div>
                <div className="row sub-header"><GoBackButton />
                    <div className="ariane-wrapper">
                        <nav className="profile-nav">
                            <ul>
                                <li className={activeLink === 'A' ? 'active-link' : ''}>
                                    <button className="cursor" onClick={() => handleLinkClick('A')}>Profile</button>
                                </li>
                                    <li className={activeLink === 'B' ? 'active-link' : ''}>
                                        <button className="cursor" onClick={() => handleLinkClick('B')}>My NFTS</button>
                                    </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
            {activeLink === 'A' ? <ProfileInfos /> : <Mynfts />}
        </div>
    )
}


export default Profile;
