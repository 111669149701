import React, { useState } from 'react';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

function CopyToClipboardIcon({ textToCopy }) {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(textToCopy);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <div className="copy-to-clipboard-icon">
            {copied && <div className="copied-message">Copied to clipboard</div>}
            <button className="copy-to-clipboard-button" onClick={copyToClipboard}>
                <ContentCopyOutlinedIcon className="profile-icon" />
            </button>
        </div>
    );
}

export default CopyToClipboardIcon;
