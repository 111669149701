import React, {useState} from 'react';
import InfoIcon from "@mui/icons-material/Info";

const PriceGasFee = ({price, quantity, currency}) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip);
    };

    const total = (price * quantity).toFixed(2);

    return (
        <div>

            <div className="nft-price">Price: {price === 0 ? "Free" : total + currency }*</div>
            <div className="nft-price-sub cursor" onClick={toggleTooltip}><span>*Excluding Gas fees</span><InfoIcon className={'tooltip-origin'}/></div>
            {showTooltip && (
                <div className="tooltip" onClick={toggleTooltip}>
                    <h3><InfoIcon />What are gas fees?</h3>
                    <p>We want to inform you that the displayed price on this page does not include gas fees. Gas fees are transaction processing costs on the Ethereum blockchain, determined at the time of your transaction on your crypto wallet. Therefore, they can't be calculated in advance or displayed on our site before the transaction.</p>
                </div>
            )}
        </div>
    )
}

export default PriceGasFee
