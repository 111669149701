import { createContext } from 'react';

export const defautMetaVrsValue = {
    isSdkLoaded: false,
    isUserLogged: false,
    isProfileLoaded: false,
    isCollectiblesLoaded: false,
    isHubLoaded: false,
    isAccessLoaded: false,
    isWhiteListed: false,
    isMintActive: false,
    //isAllowListed: false,
    userConsented: true,
    userProfile: [],
    userNfts: null,
    experiences: null,
    m: null,
    // mintPhases: {
    //     phase1: '2023-10-16T17:00:00',
    //     phase2: '2023-10-16T18:30:00',
    //     phase3: '2023-10-16T19:30:00'
    // }
}
export const MetaVrsContext = createContext(defautMetaVrsValue);