import React from 'react';

const ScrollToButton = ({ targetId, buttonText }) => {
    const scrollToSection = () => {
        const section = document.getElementById(targetId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <button className='mtvrs-button' onClick={scrollToSection}>{buttonText}</button>
    );
};

export default ScrollToButton;