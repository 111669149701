import {Link} from "react-router-dom";
import LoginButton from "../../utils/LoginButton";
import React from "react";

export default function ErrorLogin() {

    return (
        <div className="michelin-room error-page">
            <div className="michelin-panel error-zone">
                <h1>Please Login</h1>
                <p>You need to be connected to view that content</p>
                <LoginButton />
                <p>or</p>
                <div><Link to={'/'} className="mtvrs-button">Go home</Link></div>
            </div>
        </div>
    );
}