import React, { useState } from 'react';

function AccordionItem({ title, content }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`accordion-item ${isOpen ? 'open' : ''}`}>
            <div className="accordion-title" onClick={toggleAccordion}>
                <h3>{title}</h3>
                <span>
                    <svg className={`chevron ${isOpen ? 'rotate' : ''}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M12 15.5l-6.35-6.35a1.04 1.04 0 0 1 1.41-1.41L12 12.68l5.94-5.94a1.04 1.04 0 0 1 1.41 1.41z" />
                    </svg>
                </span>
            </div>
            {<div className={`accordion-content ${isOpen ? 'open' : ''}`}>{content}</div>}
        </div>
    );
}

function Accordion({ items }) {
    return (
        <div className="accordion">
            {items.map((item, index) => (
                <AccordionItem key={index} title={item.title} content={item.content} />
            ))}
        </div>
    );
}

export default Accordion;
