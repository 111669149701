import React, {useContext, useState} from 'react';
import {MetaVrsContext} from "../../store/MetaVrsContext";
import PriceGasFee from "./PriceGasFee";



function Nftcard({ price, currency, onOpen, passQuantity, multipleAllowed }) {
    const metaVrs = useContext(MetaVrsContext);
    const [mintRemaining, setMintRemaining] = useState(2);
    // const [tokenPool, setTokenPool] = useState(2);
    const [quantity, setQuantity] = useState(1);

    // useEffect(() => {
    //     if (metaVrs.isUserLogged && metaVrs.isCollectiblesLoaded){
    //         metaVrs.isWhiteListed ? setTokenPool(5) : metaVrs.isAllowListed ? setTokenPool(4) : setTokenPool(2);
    //         setMintRemaining(tokenPool - metaVrs.userNfts.collectibles.length);
    //     }
    // }, [metaVrs.isWhiteListed, metaVrs.isAllowListed, metaVrs.isCollectiblesLoaded,tokenPool, mintRemaining]);

    const handleIncrement = () => {
        if (quantity < mintRemaining ) {
            setQuantity(quantity + 1);
        }
    };
    const handleDecrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const handleTriggerBuyNow = () => {
        passQuantity(quantity)
        onOpen()
    };

    function ChangeWallet()  {
        metaVrs.m.changeWallet().then((r) => {
            console.log("user Waller Change", r)
            window.location.reload(true);
        }).catch((message) => {
            console.error("Error Changing Wallet", message)
        })
    }

    if(!metaVrs.isWhiteListed) return (
        <div className="item-card">
            <h4>YOUR WALLET IS NOT WHITELISTED FOR THIS DROP</h4>
            <button className="mtvrs-button" onClick={ChangeWallet}>Change Wallet</button>
        </div>
)

    return (
        <div className="item-card">
            {multipleAllowed ?
                <div className="quantity-selector">
                    <button className={"quantity-button"} onClick={handleDecrement}>-</button>
                    <span>{quantity}</span>
                    <button className={"quantity-button"} onClick={handleIncrement}>+</button>
                </div> :
            ""}
            <PriceGasFee price={price} quantity={quantity} currency={currency} />
            <div className="nft-buy-button">
                {/*{mintRemaining <= 0 ? (<p className="nft-max-token">Maximum number of token for your wallet reached!</p>) : ""}*/}
                {/*<button disabled={mintRemaining <= 0} onClick={handleTriggerBuyNow} className="mtvrs-button">Buy Now</button>*/}
                <button disabled={!metaVrs.isMintActive} onClick={handleTriggerBuyNow} className="mtvrs-button">Buy Now</button>
            </div>
        </div>
    );
}

export default Nftcard;
