import React from 'react';
import openSea from '../../assets/opensea.svg'
import '../../styles/sold-out.css'
import blur from "../../assets/blur.svg";
import looksrare from "../../assets/looksrare.svg";

const SoldOut = () => {
    return (
        <div className="sold-out">
            <div className="social-ico">
                <a href="https://opensea.io/collection/michelin3xplorerclub" target="_blank"><img src={openSea} alt="opensea"/></a>
                <a href="https://blur.io/collection/michelin3xplorerclub" target="_blank"><img src={blur} alt="blur"/></a>
                <a href="https://looksrare.org/collections/0x87ec044115cd9E0E09221031441640ee48b3a8F2?queryID=afb36f2bb46a8538e1bb2f32e03ea548" target="_blank"><img src={looksrare} alt="looksrare"/></a>
            </div>
            <p>This collection is sold out! Next drop is coming soon. </p>
            <div className="sold-out-placeholder"><h2>Sold Out!</h2></div>
            <p>Want to know more about us? Visit our website <a href="https://nft.michelin.com">nft.michelin.com</a></p>
        </div>
    )
}

export default SoldOut
