import React, {useContext, useEffect, useState} from 'react';
import BuyRecapListing from "./BuyRecapListing";
import PriceGasFee from "./PriceGasFee";
import {MetaVrsContext} from "../../store/MetaVrsContext";
import Loader from "../../utils/Loader";
import CheckIcon from "@mui/icons-material/Check";

const ClaimRecap = ({quantity, price, currency, passResult}) => {
    const metaVrs = useContext(MetaVrsContext);
    const [mintLoading, setMintLoading] = useState(false);
    const [mintResponse, setMintResponse] = useState(null);
    const [mintSuccess, setMintSuccess] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [isChecked, setIsChecked] = useState(false);


    useEffect(() => {
        if(openResult) {
            passResult(mintSuccess, mintResponse)
        }
    }, [mintSuccess, mintResponse]);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleClaim = async () => {
        console.log("On claim", metaVrs.experiences, quantity);
        setMintLoading(true);
        metaVrs.m.claim(metaVrs.experiences, quantity).then((response) => {
            setTimeout(() => {
                setMintResponse(response);
                setMintSuccess(true);
                setMintLoading(false);
                setOpenResult(true);
            }, 10000);
            return {mintSuccess, mintResponse };
        }).catch ((error) => {
            setMintResponse(error);
            setMintLoading(false);
            setOpenResult(true);
            return {mintSuccess, mintResponse };
        })
    };

    return (
        <>
            {mintLoading ? (
                <div className="loading-modal">
                    <h4>Awaiting confirmation, please check your wallet</h4>
                    <Loader />
                </div>
            ) : (
                <>
                    <h2 className="claim-recap-title">Checkout</h2>
                    <div className="line"></div>
                    <h4>MICHELIN h3ritage Collection</h4>
                    {/*<p className="claim-recap-quantity">({quantity} products)</p>*/}
                    <div className="nft-listing">
                        <BuyRecapListing quantity={quantity} price={price} currency={currency} />
                    </div>
                    <div className="line"></div>
                    <div className="claim-recap-price">
                        <PriceGasFee title={"Total"} price={price} quantity={quantity} currency={currency} />
                    </div>
                    <div className="withdrawl-agreement">
                        <input className="mtvrs-input-checkbox" type="checkbox" checked={isChecked} onChange={handleCheckboxChange}/>
                        <p>By checking the box and purchasing this collectible, I agree to the MICHELIN h3ritage Collection <a target="_blank" href="/publicAssets/pdfs/tcmh.pdf"> terms & conditions</a> of sales and I acknowledge and accept that I have been provided with information on the <a target="_blank" href="/publicAssets/pdfs/rowm3c.pdf">right of withdrawal</a> and that I request the start of the performance of the agreement prior to the expiration of the right of withdrawal period, hereby agreeing to lose my right of withdrawal.</p>
                    </div>
                    <button disabled={!isChecked} className="mtvrs-button" onClick={handleClaim}><CheckIcon />Mint</button>
                </>
            )}
        </>
    )
}
export default ClaimRecap;
