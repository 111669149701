import {MetaVrsContext} from "../store/MetaVrsContext";
import Loader from "../utils/Loader";
import {Link} from "react-router-dom";
import {useContext} from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


const LoginButton = () => {

    const metaVrs = useContext(MetaVrsContext);

    const onLogInClick = () => {
        metaVrs.m.displayLogin()}

    const isSdkLoaded = metaVrs.isSdkLoaded;
    if (!isSdkLoaded) {return <Loader />}

    return (
        <div>
            {metaVrs.isUserLogged ?
                <Link to={'/profile'} className="account-avatar-mini"><AccountCircleIcon sx={{color:"#FFF"}} /></Link> :
                <button className="mtvrs-button" id="loginLogout" onClick={onLogInClick}>Connect</button>
            }
        </div>
    );
}

export default LoginButton;