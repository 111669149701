import React from 'react';
import revealmh from "../../assets/revealmh.gif"

function BuyRecapListing({ quantity, price, currency }) {

    const listedItems = Array.from({ length: quantity }, (_, index) => (
        <div key={index} className="listed-nft">
            <div className="listed-nft-desc">
                <img src={revealmh} alt=""/>
                <p>MICHELIN h3ritage Collection</p>
            </div>
            <div className="listed-nft-price">
                <p>{price.toFixed(2)} {currency}</p>
            </div>
        </div>
    ));

    return (
        <>
            {listedItems}
        </>
    );
}

export default BuyRecapListing;