import React, {useContext, useEffect, useState} from 'react';
import {MetaVrsContext} from "../../store/MetaVrsContext";
import '../../styles/mynfts.css'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import etherscan from '../../assets/etherscan-logo-circle-light.svg'
import {marked} from "marked";
import Loader from "../../utils/Loader";

function Mynfts() {
    const [selectedCollectible, setSelectedCollectible] = useState(null);
    const [collectibles, setCollectibles] = useState([]);
    const metaVrs = useContext(MetaVrsContext);

    useEffect(() => {
        if(metaVrs.isCollectiblesLoaded){
           setCollectibles(metaVrs.userNfts.collectibles)
        }
    }, [metaVrs.isCollectiblesLoaded]);

    const openDetails = (collectible) => {
        setSelectedCollectible(collectible);
    };

    const closeDetails = () => {
        setSelectedCollectible(null);
    };

    return (
        <section className="profile-wrapper">
            {selectedCollectible ? (
                <CollectibleDetails
                    collectible={selectedCollectible}
                    onClose={closeDetails}
                />
            ) : (
                <>
                    <div className="profile-zone bibendum-profile"><img src="/publicAssets/bibendum_hero.png" alt=""/></div>
                    <div className="profile-zone collectibles">
                        <h2>My NFTS</h2>
                        {!metaVrs.isCollectiblesLoaded ? <Loader />:
                            <div className="collectibles-list">
                                {collectibles.length > 0 ? <CollectibleList collectibles={collectibles} openDetails={openDetails} /> : (<p className="no-collectibles-title">No collectibles available.</p>) }
                            </div>
                        }
                    </div>
                </>
            )}
        </section>
    );
}

function CollectibleDetails({ collectible, onClose }) {

    const renderer = new marked.Renderer();
    renderer.link = (href, title, text) => {
        return `<a class="linkinp" target="_blank" href="${href}" title="${title || ''}">${text}</a>`;
    };

    const renderMarkdownToHTML = (markdownString) => {
        const htmlString = marked(markdownString, { sanitize: true, renderer });
        return { __html: htmlString };
    };

    return (
        <div className="collectible-details">
            <div className="collectible-details-ariane">
                <p className="cursor" onClick={onClose}>
                    <span>My Nfts <ChevronRightIcon /></span>{collectible.name}
                </p>
            </div>
            <div className="collectible-details-center">
                <div className="collectible-details-img">
                    <img src={collectible.image} alt={collectible.name} />
                </div>
                <div className="collectible-details-descript">
                    <h1>{collectible.name}</h1>
                    <div>
                        <a href="https://etherscan.io/address/0x87ec044115cd9e0e09221031441640ee48b3a8f2" target="_blank">
                            <img src={etherscan} width="24px" />
                        </a>
                    </div>
                    <div dangerouslySetInnerHTML={renderMarkdownToHTML(collectible.description)} />
                </div>
            </div>
            <div className="collectibles-details-benefit">
                <h2>my benefits</h2>
                <p>You currently do not have any reward linked to this token</p>
            </div>
        </div>
    );
}

function CollectibleList ({collectibles, openDetails}) {
    return(
        collectibles.map((collectible, index) => (
            <div  onClick={() => openDetails(collectible)} className="collectible-item cursor" key={index}>
                <img src={collectible.image} alt={collectible.name} />
                <span>{collectible.name}</span>
            </div>
        ))
    )
}

export default Mynfts;

