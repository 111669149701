import React, { useState, useEffect } from 'react';

function CookieConsentModal() {
    const [showConsentModal, setShowConsentModal] = useState(false);

    useEffect(() => {
        const hasConsent = localStorage.getItem('cookieConsent');
        if (!hasConsent) {
            setShowConsentModal(true);
        }
        else if (hasConsent === 'true') {
            executeGA();
        }
    }, []);

    const acceptCookies = () => {
        localStorage.setItem('cookieConsent', 'true');
        executeGA();
        closeConsentModal();
    };

    const declineCookies = () => {
        localStorage.setItem('cookieConsent', 'false');
        closeConsentModal();
    };

    const executeGA = () => {
        const GAscript = document.createElement('script');

        GAscript.src = "https://www.googletagmanager.com/gtag/js?id=G-VPNW76ES7X";
        GAscript.async = true;

        document.body.appendChild(GAscript)

        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-VPNW76ES7X');
        console.log('Google Analytics is now active');
    };

    const closeConsentModal = () => {
        setShowConsentModal(false);
    };

    return (
        showConsentModal && (
            <div className="modal">
                <div className="modal-content">
                    <h3 >are you okay with cookies?</h3>
                    <p>
                        With your consent , we and our partners (METAV.RS) use cookies or similar technologies to store,
                        access, and process personal data like your visit on this website. You can withdraw your consent
                        or object to data processing based on legitimate interest at any time by clicking on "DECLINE" or
                        in our Privacy Policy on this website.
                    </p>
                    <div className="cookie-consent-buttons">
                        <button onClick={declineCookies}>
                            Decline
                        </button>
                        <button onClick={acceptCookies} id="acceptButton">
                            Agree
                        </button>
                    </div>
                </div>
            </div>
        )
    );
}

export default CookieConsentModal;