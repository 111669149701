import React, {useEffect, useState} from 'react';

const ClaimFailure = ({mintResult}) => {

    const [claimOutput, setClaimOuput] = useState(false)
    const [claimResponse, setClaimResponse] = useState()

    useEffect(() => {
        if (mintResult && mintResult.response && mintResult.response.cause) {
            setClaimResponse(mintResult.response.cause);
            setClaimOuput(true)
        } else if (mintResult && mintResult.error && mintResult.error.message) {
            setClaimResponse(mintResult.error.message);
            setClaimOuput(true)
        } else if (mintResult && mintResult.reason) {
            setClaimResponse(mintResult.reason);
            setClaimOuput(true)
        }

        console.log("Claim Failure", mintResult)
    }, [mintResult]);

    return (
        <>
            <h1 >Your mint has failed</h1>
            {claimOutput ? (
                <div>
                    <p><strong>Cause of Failure:</strong> {claimResponse}</p>
                </div>
            ) : (
                <p>Transaction failed, and no cause provided.</p>
            )}
        </>
    )
}
export default ClaimFailure;