import React, {useState} from 'react';
import "../../styles/claim-modal.css";
import CloseIcon from '@mui/icons-material/Close';
import ClaimRecap from "./ClaimRecap";
import ClaimSuccess from "./ClaimSuccess";
import ClaimFailure from "./ClaimFailure";

function ClaimModal({ quantity, onClose, price, currency }) {
    const [isResultOpen, setIsResultOpen] = useState(false)
    let [mintResponseResult, setMintResponseResult] = useState({})
    let [mintSuccessResult, setMintSuccessResult] = useState(false)

    const handleResult = (mintSuccess, mintResponse) => {
        setMintResponseResult(mintResponse);
        setMintSuccessResult(mintSuccess);
        openResult()
    }

    const openResult = () => {
        setIsResultOpen(true);
    };

    const closeResult = () => {
        setIsResultOpen(false);
    };

    return (
        <div className="buy-recap-modal">
            <div className="buy-recap-modal-content">
                <CloseIcon className="close" onClick={onClose} />
                {isResultOpen ?
                    mintSuccessResult ? <ClaimSuccess quantity={quantity} price={price} currency={currency} mintResult={mintResponseResult}/>
                        : <ClaimFailure mintResult={mintResponseResult}/>
                    : <ClaimRecap quantity={quantity} price={price} currency={currency} passResult={handleResult}/>
                }
            </div>
        </div>
    );
}

export default ClaimModal;