    import React, {useContext} from 'react';

    import CookieConsentModal from "../components/ConsentGaModal";
    import Accordion from "../utils/Accordions";
    import {accordionData} from "../content/accordionContent";
    import {Footer} from "../components/home/Footer";
    import ImageLoader from "../utils/ImageLoader";
    import BibendumHero from "../assets/bibendum-hero-anim.gif"
    import Header from "../components/home/Header";

    import openSea from "../assets/opensea.svg"
    import blur from "../assets/blur.svg"
    import looksrare from "../assets/looksrare.svg"
    import HeroMintAccess from "../components/home/HeroMintAccess";
    import {MetaVrsContext} from "../store/MetaVrsContext";
    import {FreeMint} from "../components/home/freeMint";

function Home() {

    const metaVrs = useContext(MetaVrsContext);

    return (
        <>
            <CookieConsentModal />
            <Header />
            <main>
                <section className="hero">
                    <div className="hero-content">
                        <div className="hero-col">
                            <ImageLoader src={BibendumHero} alt={"Bibendum Michelin Explorer"} imgClass={""} />
                        </div>
                        <div className="hero-col">
                            <img className="hero-logo" src={"publicAssets/logo_michelin_explorer.svg"} alt="Michelin Explorer"/>
                            {metaVrs.isMintActive ? <FreeMint /> : <p className="italic">Unique Michelin Man NFTs: the keys to go further in our universe.</p>}
                            <div className="social-ico">
                                <a href="https://opensea.io/collection/michelin3xplorerclub" target="_blank" rel="noreferrer"><img src={openSea} alt="opensea"/></a>
                                <a href="https://blur.io/collection/michelin3xplorerclub" target="_blank" rel="noreferrer"><img src={blur} alt="blur"/></a>
                                <a href="https://looksrare.org/collections/0x87ec044115cd9E0E09221031441640ee48b3a8F2?queryID=afb36f2bb46a8538e1bb2f32e03ea548" target="_blank" rel="noreferrer"><img src={looksrare} alt="looksrare"/></a>
                            </div>
                        </div>
                    </div>
                    <div className="clipped-wrapper">
                        <svg className="svg">
                            <clipPath id="hero-clip-path" clipPathUnits="objectBoundingBox">
                                <path d="m0,0 v0.927 c0.06,0.042,0.261,0.073,0.5,0.073 s0.44,-0.031,0.5,-0.073 V0 H0"></path>
                            </clipPath>
                        </svg>
                        <div className="clipped">
                            <video className="clipped-video" poster={"publicAssets/fallback-hero.webp"} autoPlay muted loop>
                                <source src={"publicAssets/background-hero.mp4"} type="video/mp4"/>
                            </video>
                        </div>
                    </div>
                </section>
                <section id="intro">
                    <div className="text-center the-club">
                        <h2>Michelin 3xplorer club</h2>
                        <p>
                            Welcome to the Michelin 3xplorer Club, an exclusive community with 5000 unique digital
                            collectibles featuring the iconic Michelin Man. Immerse yourself in the worlds of mobility,
                            gastronomy, travel, and beyond, to try to unlock extraordinary experiences that define the
                            Michelin universe. Your digital collectible is your passport to a realm where limitless
                            expertise awaits.
                        </p>
                        <p className="blue-accent">
                            Your Michelin NFT gives you access to
                        </p>
                    </div>
                </section>
                <section id="the-project">
                    <div>
                        <div className="club-container the-club">
                            <div className="club-column">
                                <p className="club-count">01</p>
                                <h3>A piece of Michelin’s History</h3>
                                <p>Get a unique collectible version of the Michelin Man, a piece of Michelin's history.
                                    A piece of particular value as it will forever represent Michelin's first steps on
                                    the blockchain.</p>
                            </div>
                            <div className="club-column">
                                <p className="club-count">02</p>
                                <h3>Money-can’t-buy real life experiences</h3>
                                <p>Raffles to get a chance to win money-can’t-buy Michelin Experiences (Races, Michelin
                                    starred restaurants, diners, exclusive Motorsport & heritage items ...)</p>
                            </div>
                            <div className="club-column">
                                <p className="club-count">03</p>
                                <h3>Exclusive digital experiences</h3>
                                <p>Access a private community of mobility, food and travel enthusiasts. Potential NFT
                                    drops and digital experiences.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="benefit" className="benefit">
                    <div className="justify">
                        <h2>Dear 3xplorers</h2>
                        <p><strong>Are you ready to explore the world with us? 🤠</strong></p>
                        <p>You know that this club is about sharing unique experiences around <span className="yellow-accent" >mobility, travel, sport and gastronomy</span>.
                            As Michelin partners with some of the most <span className="yellow-accent" >prestigious actors</span> of
                            these industries, the Michelin 3xplorer Club gives you the chance to be a part of it.</p>
                        <p><strong>Here is a sneak peek of some of the benefits we have in store for
                            our members in Q4... 👀</strong></p>
                    </div>
                    <div className="benefit-container">
                        <div className="benefit-col"><img src={"./publicAssets/Event.jpg"} alt="3xplorer club evenement"/></div>
                        <div className="benefit-col"><img src={"./publicAssets/motogp.jpg"} alt="motogp"/></div>
                        <div className="benefit-col"><img src={"./publicAssets/Restaurant.jpg"} alt="restaurant michelin"/></div>
                        <div className="benefit-col"><img src={"./publicAssets/3xplorerGuide.jpg"} alt="3xplorer guide"/></div>
                        <div className="benefit-col"><img src={"./publicAssets/Merch.jpg"} alt="Merch"/></div>
                    </div>
                    <div>
                        <h3>Important things to note about the RAffles BENEFITS</h3>
                        <p><strong>How to be selected?</strong><br/>
                            Luck 🍀 and commitment 💪<br/>
                            Some prize draws will be purely random among our holders who registered for the raffles.
                            Others will be exclusive to the people at the top of the Zealy leaderboard, but also
                            randomly selected in case they are tied.</p>
                        <p><strong>How Zealy works ?</strong><br/>
                            Join the Michelin 3xplorer space on zealy.io and complete as many quests as you can to make
                            it to the top of the leaderboard.</p>
                        <p><strong>Subject to change❗</strong><br/>
                            ️As most of these experiences are linked to real life events, we can’t be 100% sure that
                            they will take place. However if there is any change we will make sure to replace it by
                            another event. We may add more events to this roadmap. But as we are not sure yet,
                            we prefer to under promise. 😉</p>
                        <p>Ready? 🤠🚦</p>
                        <br />
                    </div>
                    <div>
                        <picture className="absolute-bg">
                            <source src={"publicAssets/roadmap_bg.jpg"} type="image/jgp"/>
                            <source src={"publicAssets/roadmap_bg.webp"} type="image/webp"/>
                            <img src={"publicAssets/roadmap_bg.jpg"} alt="roadmap background" className="absolute-bg"/>
                        </picture>
                    </div>
                </section>
                <section id="faq" className="faq justify">
                    <h2>FAQ</h2>
                    <Accordion items={accordionData} />
                </section>
            </main>
            <Footer />
        </>
    );
}

export default Home;