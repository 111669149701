export const accordionData = [
    {
        title: 'What is an NFT?',
        content: 'NFT stands for ‘non-fungible token’. Non-fungible means that something is unique\n' +
            '                and can’t be replaced. By contrast, physical money and cryptocurrencies are\n' +
            '                fungible, which means they can be traded or exchanged for one another. Every NFT\n' +
            '                contains a digital signature which makes each one unique. NFTs are digital publicAssets and\n' +
            '                could be photos, videos, audio files, or another digital format. NFT examples include\n' +
            '                artwork, comic books, sports collectibles, trading cards, games and more.',
    },
    {
        title: 'What is a blockchain?',
        content: 'A blockchain is a register, a large database that has the particularity of being shared\n' +
            '                simultaneously with all its users, all of whom are also holders of this register, and all\n' +
            '                of whom also have the ability to enter data into it, according to specific rules set by a\n' +
            '                computer protocol that is highly secure thanks to cryptography.',
    },
    {
        title: 'What is a mint?',
        content: 'It is the act of forging or creating an NFT and registering it on a blockchain. It is the\n' +
            '                same principle as minting a coin in real life.',
    },
    {
        title: 'Why is Michelin launching the Michelin 3xplorer Club (M3C)?',
        content: 'Since the beginning of its history, Michelin has helped the explorers around the\n' +
            '                world to travel and discover new cities or restaurants. This is why Michelin launched\n' +
            '                its M3C NFTs collection.',
    },
    {
        title: 'How to join the Michelin 3xplorer Club?',
        content: 'To join the M3C, you need to possess one of the 5000 NFTs of the Michelin 3xplorer\n' +
            '                Club Collection. All information is available on our communication channels.',
    },
    {
        title: 'What do I need to buy a Michelin NFT?',
        content: "To purchase a Michelin NFT, you'll need a Metamask or WalletConnect compatible wallet. You will need" +
            " to have at least 0.05 ETH plus gas fees. Note that the gas fees cannot be technically calculated in" +
            " advance and are only determined at the time of your transaction on your digital wallet (crypto wallet)." +
            "  However, you will be informed on your digital wallet of the amount of the gas fees, prior to confirming" +
            " your purchase.",
    },    {
        title: 'Why should I be part of the Michelin 3xplorer Club?',
        content: "Being part of the M3C will allow you to own a unique piece of the Michelin Man, the first ever" +
            " digital collectible from Michelin. You will also have the right to access the Michelin 3xplorer Club" +
            " private Discord channel, and to participate in raffles to try to win exclusive experiences in all" +
            " Michelin universes (sport, travel, gastronomy, etc.)",
    },
];