import { useRouteError } from "react-router-dom";

export default function ErrorRouting() {
    const error = useRouteError();
    console.error(error);

    return (
        <div className="michelin-room error-page">
            <div className="mtvrs-connection-inner error-zone">
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>{error.statusText || error.message}</i>
                </p>
            </div>
        </div>
    );
}