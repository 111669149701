import React from 'react';
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import {Link} from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import CopyToClipboardIcon from "../../utils/ContentCopyOutlinedIcon";


const ClaimSuccess = ({quantity, price, currency, mintResult}) => {


    function getPropertyIfExists(obj, propertyName) {
        if (obj && obj.hasOwnProperty(propertyName)) {
            return obj[propertyName];
        } else {
            return false;
        }
    }

    const propertyName = 'hash';
    const claimOutput = getPropertyIfExists(mintResult, propertyName);

    console.log("Claim Success", mintResult)


    const total = (price * quantity).toFixed(2);

    return (
        <>
            <h2 className="claim-result-title">Congratulations!</h2>
            <p>Your claim is complete!</p>
            <div className="claim-success-tx">
                {claimOutput ? (
                    <>
                        <p>Transaction id</p>
                        <p>{claimOutput.substring(0, 4)}...${claimOutput.substring(claimOutput.length - 12)}</p>
                        <div className="claim-success-copy"><CopyToClipboardIcon textToCopy={claimOutput}/></div>
                    </>
                ) : (
                    <p>Transaction successful, but no transaction hash available.</p>
                )}
            </div>
            <div className="claim-success-info"><InfoIcon/><p>Your transaction is getting processed on the blockchain. Check your profile in a few minutes to see your MICHELIN h3ritage NFT.</p></div>
            <p className="claim-success-price">{total} {currency}</p>
            <p className="claim-success-links">
                <a target="_blank" href="/publicAssets/pdfs/tcmh.pdf">Download the Terms & Conditions of Sale</a>
                <a target="_blank" href="/publicAssets/pdfs/rowm3c.pdf">Download the confirmation of the loss of the right of withdrawal</a>
            </p>
            <Link to={"/"} className="mtvrs-button">Go Back to home Page</Link>
        </>
    )
}
export default ClaimSuccess;