import React, {useContext, useEffect, useState} from 'react'
import {MetaVrsContext} from "../store/MetaVrsContext";
import nft_drop_isnotwhitelisted from "../assets/nft_drop_img_iswhiteliested.png"
import nft_drop_isnotconnected from "../assets/nft_drop_isnotconnected.png"
import nft_drop_iswhitelisted from "../assets/nft_drop_isnotwhitelisted.png"
import Loader from "../utils/Loader";


const CheckWhiteList = () => {
    const metaVrs = useContext(MetaVrsContext)
    const [bibendum, setBibendum] = useState(null)
    const [messageContent, setMessageContent] = useState(null)

    useEffect(() =>{
        setBibendum(!metaVrs.isUserLogged ? nft_drop_isnotconnected : metaVrs.isWhiteListed ? nft_drop_iswhitelisted : metaVrs.isAllowListed ? nft_drop_iswhitelisted : nft_drop_isnotwhitelisted);
        setMessageContent(!metaVrs.isUserLogged ? <IsNotConnected />: !metaVrs.isAccessLoaded ? <Loader /> : metaVrs.isWhiteListed ? <IsWhiteListed/> : metaVrs.isAllowListed ? <IsAllowListed/> : <IsNotWhiteListed/>);
    }, [metaVrs.isUserLogged, metaVrs.isWhiteListed, metaVrs.isAllowListed, metaVrs.isAccessLoaded])


    if (!metaVrs.isSdkLoaded) {return <Loader />}

    let borderStyle = null ;


    const onLogInClick = () => {
        metaVrs.m.displayLogin()}

    const IsNotConnected = () => {
        return (
            <button className="mtvrs-button" id="loginLogout" onClick={onLogInClick}>Log In</button>
        )
    }

    const IsNotWhiteListed = () => {
        borderStyle = {
            borderRadius: "10px",
            border: "1px solid #FF4848"
        } ;
        return (
            <div className="the-message" style={borderStyle}>
                <svg xmlns="http://www.w3.org/2000/svg" height="42px" viewBox="0 0 512 512"><path fill="white" d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/>
                </svg><h4>Unfortunately, you're neither part of the whitelist nor allowlist.</h4>
            </div>
        )
    }

    const IsWhiteListed = () => {
        borderStyle = {
            borderRadius: "10px",
            border: "1px solid #9FF9D9"
        } ;
        return (
            <div className="the-message" style={borderStyle}>
                <svg xmlns="http://www.w3.org/2000/svg" height="42px" viewBox="0 0 512 512"><path fill="white" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                </svg><h4>CONGRATS, YOU ARE WHITELISTED!</h4>
            </div>
        )
    }

    const IsAllowListed = () => {
        borderStyle = {
            borderRadius: "10px",
            border: "1px solid #9FF9D9"
        } ;
        return (
            <div className="the-message" style={borderStyle}>
                <svg xmlns="http://www.w3.org/2000/svg" height="42px" viewBox="0 0 512 512"><path fill="white" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                </svg><h4>CONGRATS, YOU ARE on the allowlist!</h4>
            </div>
        )
    }

    return (
        <section className="whitelist-wrapper">
            <div className="whitelist-content">
                <div><img className="bibendum-wl" src={bibendum} alt=""/></div>
                <div>
                    <div className="whitelist-infos">
                        <h2>
                            Wallet Checker
                        </h2>
                        <div>
                            <p>Welcome to the 3xplorer Club wallet scan. Use this tool to check if your wallet address is on the whitelist or allowlist for our upcoming NFT drop.</p>
                            <p>Exclusive access for whitelisted will start on October 16 at 5:00 p.m. UTC+2, followed by allowlisted at 6:30 p.m. UTC+2. The public sale will open at 7:30 p.m. UTC+2. Join us for this exciting event!!</p></div>
                        <div className="whitelist-message">
                            {messageContent}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default CheckWhiteList;