import React from 'react';
import {Link} from "react-router-dom";

export const FreeMint = () => {
    return (
        <div className="free-mint">
            <h2>You own a Michelin nft?<br />
                It is time to claim what is yours </h2>
            <Link className="wl-button" to={'/mint'}>FREE CLAIM</Link>
        </div>
    )
}
