import React, {useEffect, useState} from 'react';
import LoginButton from "../../utils/LoginButton";
import ScrollToButton from "./ScrollToButton";

function Header() {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 600);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 600);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <header className="header">
            {isMobileView ? (
                <>
                    <div className="row header-top-mobile">
                        <div>
                            <img src={"publicAssets/logo_michelin.svg"} height="60px" alt="logo michelin explorer" title="Michelin explorer"/>
                        </div>
                        <div>
                            <LoginButton />
                        </div>
                    </div>
                    <div className="row header-bot-mobile">
                        <nav className="nav-wrapper">
                            <div className="nav-menu">
                                <ScrollToButton buttonText="The Project" targetId="the-project"/>
                                <ScrollToButton buttonText="Benefits" targetId="benefit"/>
                                <ScrollToButton buttonText="FAQ" targetId="faq"/>
                            </div>
                            <div className="header-social-ico">
                                <a href="https://twitter.com/Michelin3xplore" target="_blank"><img src={"publicAssets/twitter.svg"} alt="twitter"/></a>
                                <a href="https://discord.gg/michelin" target="_blank"><img src={"publicAssets/discord.svg"} alt="discord"/></a>
                            </div>
                        </nav>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <img src={"publicAssets/logo_michelin.svg"} height="60px" alt="logo michelin explorer" title="Michelin explorer"/>
                    </div>
                    <nav className="nav-wrapper">
                        <div className="nav-menu">
                            <ScrollToButton buttonText="The Project" targetId="the-project"/>
                            <ScrollToButton buttonText="Benefits" targetId="benefit"/>
                            <ScrollToButton buttonText="FAQ" targetId="faq"/>
                        </div>
                        <div className="header-social-ico">
                            <a href="https://twitter.com/Michelin3xplore" target="_blank"><img src={"publicAssets/twitter.svg"} alt="twitter"/></a>
                            <a href="https://discord.gg/michelin" target="_blank"><img src={"publicAssets/discord.svg"} alt="discord"/></a>
                        </div>
                    </nav>
                    <div>
                        <LoginButton />
                    </div>
                </>
            )}
        </header>
    );
}

export default Header;

