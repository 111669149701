import React from 'react';

import "../../styles/footer.css"

import openSea from "../../assets/opensea.svg";
import blur from "../../assets/blur.svg";
import looksrare from "../../assets/looksrare.svg";

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div>
                    <img src={"publicAssets/logo_michelin.svg"} height="60px" alt="logo michelin explorer" title="Michelin explorer"/>
                </div>
                <div className="social-ico">
                    <a href="https://opensea.io/collection/michelin3xplorerclub" target="_blank"><img src={openSea} alt="opensea"/></a>
                    <a href="https://blur.io/collection/michelin3xplorerclub" target="_blank"><img src={blur} alt="blur"/></a>
                    <a href="https://looksrare.org/collections/0x87ec044115cd9E0E09221031441640ee48b3a8F2?queryID=afb36f2bb46a8538e1bb2f32e03ea548" target="_blank"><img src={looksrare} alt="looksrare"/></a>
                </div>
                <div>
                    <ul className="nav-footer">
                        <li><a target="_blank" rel="noreferrer" href="/publicAssets/pdfs/tcmh.pdf">Terms &amp; Conditions</a></li>
                        <li><a target="_blank" rel="noreferrer" href="/publicAssets/pdfs/tum3c.pdf">Terms of Use</a></li>
                        <li><a target="_blank" rel="noreferrer" href="/publicAssets/pdfs/pcpm3c.pdf">Privacy Policy</a></li>
                        <li><a target="_blank" rel="noreferrer" href="/publicAssets/pdfs/lnm3c.pdf">Legal Notice</a></li>
                    </ul>
                </div>
            </div>
            <div>
                <picture className="absolute-bg">
                    <source src={"publicAssets/background-footer.jpg"} type="image/jgp"/>
                    <source src={"publicAssets/background-footer.webp"} type="image/webp"/>
                    <img src={"publicAssets/background-footer.jpg"} alt="footer background" className="absolute-bg"/>
                </picture>
            </div>
        </footer>
    )
}
