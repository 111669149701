import React, {useContext, useEffect, useState} from 'react';
import {MetaVrsContext} from "../store/MetaVrsContext";

import Loader from "../utils/Loader";
import GoBackButton from "../components/GoBackButton";

import "../styles/michelin-room.css"
import "../styles/mint.css"

import Logo from "../assets/logo_michelin.svg";
import heritage from "../assets/michelin-heritage-collection.png"
import AccountCircleIcon from "@mui/icons-material/AccountCircle";


import {Link} from "react-router-dom";
import ProgressBar from "../components/MintPage/ProgressBar";
import Nftcard from "../components/MintPage/Nftcard";
import ErrorLogin from "./errors/ErrorLogin";
import ClaimModal from "../components/MintPage/ClaimModal";
import SoldOut from "../components/MintPage/SoldOut";
import CountdownTimer from "../components/MintPage/CountDownTimer";
import ErrorNoMint from "./errors/ErrorNoMint";


const Mint =  () => {
    const metaVrs = useContext(MetaVrsContext);

    const [loadingSupply, setLoaddingSupply] = useState(false);
    const [nftQuantity, setNftQuantity] = useState(1);
    const [nftSupply, setNftSupply] = useState(0);
    const [allowMultiple, setAllowmultiple] = useState(false);
    const [soldOut, setSoldOut] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const [targetDate, setTargetDate] = useState("2023-11-03T16:00:00");

    const price = 0;
    const currency = 'ETH';

    useEffect(() => {
        if(metaVrs.isSdkLoaded && metaVrs.isHubLoaded) {
            setNftQuantity(metaVrs.experiences.configuration.nfts.quantity)
            setTargetDate(metaVrs.experiences.configuration.when.to)
            metaVrs.m.getCollectibleSupply(metaVrs.experiences.eid).then((r) => {
                setNftSupply(r.supply)
                setLoaddingSupply(true)
            }).catch((err) => {
                console.log('Oops', err)
            })
        }
        nftSupply >= nftQuantity ? setSoldOut(true) : setSoldOut(false);


    },[metaVrs.isSdkLoaded, metaVrs.isHubLoaded, metaVrs.experiences, nftQuantity, nftSupply])

    if (!metaVrs.isMintActive) {return <ErrorNoMint />}
    if (!metaVrs.isSdkLoaded) {return <Loader />}
    if (!metaVrs.isUserLogged) {return <ErrorLogin />}

    const handleQuantity = (newQuantity) => {
        setQuantity(newQuantity);
    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="michelin-room mint-page">
            <header className="michelin-room-header">
                <div className="row">
                    <img src={Logo} height="60px" alt=""/>
                    {metaVrs.isUserLogged ? <Link to={'/profile'} className="account-avatar-mini"><AccountCircleIcon sx={{color:"#FFF"}} /></Link> : ""}
                </div>
                <div className="whitelist-sub-header"><GoBackButton /></div>
            </header>
                <div className="nft-content">
                    <div className="nft-element nft-img">
                        <img src={heritage} alt=""/>
                    </div>
                    <div className="nft-element nft-infos">
                        <h2>MICHELIN h3ritage Collection</h2>
                        {soldOut && metaVrs.isHubLoaded ? <SoldOut /> :
                            <>
                                <p>Step back in time with our Heritage NFTs! Get a cool glimpse into Michelin's amazing story and hang out with our legendary Michelin Man. Revel in the timeless allure of these digital treasures and relive the magic of Michelin's past. Grab these NFTs and own a fun piece of Bibendum's adventures!</p>
                                {loadingSupply && metaVrs.isAccessLoaded ? <>
                                        <ProgressBar start={0} current={nftSupply} end={nftQuantity}/>
                                        <CountdownTimer targetDate={targetDate} />
                                        <p className="date-highlights">Free claim available from<strong> November 10th 4pm to November 13th 4pm 2023 CET</strong></p>
                                        <Nftcard multipleAllowed={allowMultiple} price={price} onOpen={openModal} passQuantity={handleQuantity} currency={currency}/>
                                    </>
                                    : <Loader />
                                }
                            </>
                        }
                        {/*<p>By minting, you're agreeing to Michelin's h3ritage collection <a className="linkinp" href="/publicAssets/pdfs/tcm3c.pdf" target="_blank" rel="noreferrer">terms & condition</a></p>*/}
                    </div>
                </div>
            {isModalOpen ? <ClaimModal quantity={quantity} onClose={closeModal} price={price} currency={currency}/> : null}
        </div>
    )
}

export default Mint;