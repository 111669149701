import { useEffect, useState } from 'react';

const useMetaVrsSdk = url => {
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        script.onload = () => {
            setIsLoaded(true);
        };

        return () => {
            document.body.removeChild(script);
        }

    }, [url]);

    return isLoaded;
};

export default useMetaVrsSdk;