import React, {useEffect, useState} from 'react';
import {defautMetaVrsValue, MetaVrsContext} from './store/MetaVrsContext';

import './styles/custom-metavrs.css'
import './styles/michelin-styles.css'

import useMetaVrsSdk from "./hooks/useMetaVrsSdk";
import Home from "./pages/Home";
import ErrorRouting from "./pages/errors/Error";
import Profile from "./pages/Profile";

import {createBrowserRouter, RouterProvider} from "react-router-dom";
import WhiteList from "./pages/WhiteList";
import Mint from "./pages/Mint";

import mintStatus from "./utils/mintStatus";


function App() {
  const isSdkLoaded = useMetaVrsSdk('https://sdk2.metav.rs/metavrs-sdk-react.0.2.production.min.js')
  const [metaVrsInfo, setMetaVrsInfo] = useState(defautMetaVrsValue);

  const experienceEid = "3637628926239631424";
  const whiteListEid = "-4526272958741732993";
  //const allowListEid = "5342317678973312412";

  const mintStartDateTime = '2023-11-10T10:00:00';
  const mintDurationHours = 72;

  useEffect(() => {

    const updateContext = () => {
      setMetaVrsInfo(prevState => {
        return {...prevState, ...defautMetaVrsValue};
      });
    }

    defautMetaVrsValue.isSdkLoaded = isSdkLoaded;

    if(isSdkLoaded) {
      // eslint-disable-next-line no-undef
      const m = new METAVRS({
        API_KEY:  '-6177062026235328368',
        consentOptions: {
          title : "In order to access the experience, please accept our terms of use",
          terms : {
            label : "I have read and I accept the Michelin 3xplorer account",
            link : {
              label : "Terms of use",
              url : "/publicAssets/pdfs/tum3c.pdf"
            }
          },
          privacyPolicy : {
            label : "To find out how Michelin uses and processes your personal data, please refer to our",
            link : {
              label : "Privacy policy",
              url : "/publicAssets/pdfs/pcpm3c.pdf"
            }
          }
        }
      });


      // Check if sale is live
      const isEventCurrentlyActive = mintStatus(mintStartDateTime, mintDurationHours);
      if(isEventCurrentlyActive) {
        defautMetaVrsValue.isMintActive = isEventCurrentlyActive;
        updateContext();
        console.log('Mint is live starting:', mintStartDateTime, 'for ', mintDurationHours, 'h' );
      } else {
        console.log('No live mint')
      }


      // Upon user connection
      m.onConnect((user) => {
        console.log("User connected", user);
        defautMetaVrsValue.isUserLogged = true;
        defautMetaVrsValue.userConsented = user.consented;
        updateContext()
        if (metaVrsInfo.isUserLogged && metaVrsInfo.userConsented) {
          getUserInfos();
        }
      });

      if(metaVrsInfo.m === null) {
        defautMetaVrsValue.m = m;
        updateContext()
      }

      const getUserInfos = () => {
        getProfile()
        getHub()
        getUserCollectibles()
      }

      const getProfile = () => {
        m.getProfile().then((profile) => {
            defautMetaVrsValue.userProfile = profile;
            defautMetaVrsValue.isProfileLoaded = true;
          updateContext()
            console.log('Get Profile ', profile)
          }).catch((err) => {
            console.log("Error getting Profile", err);
          });
      }

      const getHub = () => {
        m.getHub().then((hub) => {
          // console.log('Hub:', hub)
          const filteredExperiences = hub.experiences.filter( x => x.eid === experienceEid );
          if(filteredExperiences.length > 0) {
            defautMetaVrsValue.experiences = filteredExperiences[0];
            defautMetaVrsValue.isHubLoaded = true;
            console.log('Get Experience ', defautMetaVrsValue.experiences)
            updateContext()
          } else {
            console.log('No experience found')
          }
        }).catch((err) => {
          console.log("Error getting Hub", err);
        });
      }

      const getUserCollectibles = () => {
        m.getUserCollectibles().then((nfts) => {
          console.log('Get User Collectibles ', nfts)
          defautMetaVrsValue.userNfts = nfts
          defautMetaVrsValue.isCollectiblesLoaded = true;
          updateContext()
          getUserAccess()
          }).catch((err) => {
            console.log("Error getting Collectibles", err);
          });
      }

      const getUserAccess = () => {

        //Check Whitelist
        defautMetaVrsValue.m.checkWhitelistWallet(defautMetaVrsValue.experiences.configuration.collection.eid, defautMetaVrsValue.userProfile.wallet, whiteListEid).then((rWL) => {
          defautMetaVrsValue.isWhiteListed = true;
          updateContext()
          console.log("The user wallet is whitelisted for this collection")
        }).catch(() => {
          console.log("The user wallet is NOT whitelisted for this collection")
        })

        // //Check AllowList
        // if(!metaVrsInfo.isWhiteListed) {
        //   metaVrsInfo.m.checkWhitelistWallet(metaVrsInfo.experiences.configuration.collection.eid, metaVrsInfo.userProfile.wallet, allowListEid).then((rAL) => {
        //     defautMetaVrsValue.isAllowListed = true;
        //     updateContext()
        //     console.log("The user wallet is allowlisted for this collection")
        //   }).catch(() => {
        //     console.log("The user wallet is NOT allowlisted for this collection")
        //   })
        // }
        defautMetaVrsValue.isAccessLoaded = true;
        updateContext()
      }
    }
  }, [isSdkLoaded,metaVrsInfo.isCollectiblesLoaded])

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorRouting />,
    },
    {
      path: '/profile',
      element: <Profile />,
    },
    {
      path: '/whitelist',
      element: <WhiteList />,
    },
    {
      path: '/mint',
      element: <Mint />,
    }
  ]);

  return (
      <>
        <MetaVrsContext.Provider value={metaVrsInfo}>
          <metavrs-connection theme="dark" wallet="selfcustodian" consent="integrated"></metavrs-connection>
          <RouterProvider router={router} />
        </MetaVrsContext.Provider>
      </>
  );
}

export default App;
