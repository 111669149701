import React from 'react';

function ProgressBar({ start, end, current }) {

    const progress = ((current - start) / (end - start)) * 100;
    const clampedProgress = Math.min(100, Math.max(0, progress));
    const clampedProgressClean = parseFloat(clampedProgress.toFixed(2));

    return (
        <>
            <div className="progress-bar">
                <div className="progress" style={{ width: `${clampedProgressClean}%` }}></div>
            </div>
            <div className="progress-subtext">
                <div className="progress-pourcentage">{`${clampedProgressClean}%`}</div>
                <div>{`${current}/${end} NFTS minted`}</div>
            </div>
        </>
    );
}

export default ProgressBar;