import React, {useContext} from 'react';
import {MetaVrsContext} from "../store/MetaVrsContext";

import Loader from "../utils/Loader";
import GoBackButton from "../components/GoBackButton";
import CheckWhiteList from "../components/CheckWhiteList";

import "../styles/whitelist.css"

import Logo from "../assets/logo_michelin.svg";
import {Link} from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";


const WhiteList =  () => {
    const metaVrs = useContext(MetaVrsContext);

    if (!metaVrs.isSdkLoaded) {return <Loader />}

    return (
        <div className="michelin-whitelist">
            <header>
                <div className="row">
                    <img src={Logo} height="60px" alt=""/>
                    {metaVrs.isUserLogged ? <Link to={'/profile'} className="account-avatar-mini"><AccountCircleIcon sx={{color:"#FFF"}} /></Link> : ""}
                </div>
                <div className="whitelist-sub-header"><GoBackButton /></div>
            </header>
            <CheckWhiteList />
        </div>
    )
}

export default WhiteList;
