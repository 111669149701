import React, { useState, useEffect } from 'react';
import Loader from "./Loader";

function ImageLoader({ src, alt, imgClass}) {
    const [isLoading, setIsLoading] = useState(true);
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    useEffect(() => {
        const image = new Image();
        image.src = src;

        image.onload = () => {
            setIsLoading(false);
            setIsImageLoaded(true);
        };

        image.onerror = () => {
            setIsLoading(false);
            setIsImageLoaded(false);
        };
    }, [src]);

    return (
        <div>
            {isLoading && <Loader />}

            {isImageLoaded ? (
                <img src={src} alt={alt} className={imgClass} />
            ) : null}
        </div>
    );
}

export default ImageLoader;