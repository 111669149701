import React, {useContext} from 'react';


import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {useNavigate} from "react-router-dom";
import {MetaVrsContext} from "../../store/MetaVrsContext";
import CopyToClipboardIcon from "../../utils/ContentCopyOutlinedIcon";

export const ProfileInfos = () => {
    const metaVrs = useContext(MetaVrsContext);

    const currentUserEmail = metaVrs.userProfile.email;
    const usrWallet = metaVrs.userProfile.wallet;
    const navigate = useNavigate()

    let currentUserWallet = null;
    if (metaVrs.isProfileLoaded) {
        currentUserWallet = `${usrWallet.substring(0, 4)}...${usrWallet.substring(usrWallet.length - 12)}`;
    }

    function ChangeWallet()  {
        metaVrs.m.changeWallet().then((r) => {
            console.log("user Waller Change", r)
            window.location.reload(true);
        }).catch((message) => {
            console.error("Error Changing Wallet", message)
        })
    }

    const onLogOutClick = () => {

        try{
            metaVrs.m.logout()
            navigate(0);
        } catch(err) {
            console.log(err)
        }
    }

    return (
        <section className="profile-wrapper">
            <div className="profile-zone bibendum-profile"><img className="" src="/publicAssets/bibendum_hero.png" alt=""/></div>
            <div className="profile-zone user-infos">
                <div className="user-infos-wrapper">
                    <div>
                        <h2>General Information</h2>
                        <p>Email Address</p>
                        <div className="profile-field" >{currentUserEmail}</div>
                    </div>
                    <div>
                        <p>Wallet</p>
                        <div className="profile-wallet">
                            <div className="profile-field" >
                                <AccountBalanceWalletOutlinedIcon />
                                <p>{currentUserWallet}</p>
                                <CopyToClipboardIcon textToCopy={metaVrs.userProfile.wallet}/>
                            </div>
                            <div className="change-wallet cursor" >
                                <ModeEditOutlinedIcon className="profile-icon" onClick={ChangeWallet}  />
                            </div>
                        </div>
                    </div>
                    <button className="profile-logout cursor" onClick={onLogOutClick}>
                        <LogoutOutlinedIcon /> Log Out
                    </button>
                </div>


            </div>
        </section>

    )
}
