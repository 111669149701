import { useNavigate} from "react-router-dom";
import React from "react";

const GoBackButton = () => {
    let navigate = useNavigate();
    return (
        <>
            <button className="go-back-button" onClick={() => navigate(-1)}>
                <svg xmlns="http://www.w3.org/2000/svg" height="0.9em" viewBox="0 0 320 512">
                    <path fill="white" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/>
                </svg>
                Back
            </button>
        </>
    );
};

export default GoBackButton;