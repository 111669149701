import {Link} from "react-router-dom";
import React from "react";
import "../../styles/error.css"

export default function ErrorNoMint() {

    return (
        <div className="michelin-room">
            <div className="michelin-panel error-zone error-nomint">
                <h3>There are currently no active drop</h3>
                <div><Link to={'/'} className="mtvrs-button">Project home page</Link></div>
            </div>
        </div>
    );
}